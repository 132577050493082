import adminLayout from '@/layouts/Admin'
import httpAxios from '@/utils/http-axios.js'
import formatDateMixin from '@/mixins/format-date'

export default {
    name: 'MemoryCardsImages',
    data () {
        return {
            memoryCardsImages: {}
        }
    },
    mixins: [formatDateMixin],
    components: {
        adminLayout
    },
    mounted() {
        this.getMemoryCardsImages()
    },
    methods: {
        getMemoryCardsImages() {
            const self = this;

            httpAxios({
                url: this.$backendUrl + '/api/v1/memory-cards-images',
                method: 'GET'
            }).then(response => {
                self.memoryCardsImages = response.data.rows;
            })
        }
    }
}
